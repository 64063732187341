.commandBar {
  grid-area: commandbar;
  display: flex;
  width: 100%;
  background: #3b3b3b;
  align-items: center;

  .button {
    border: transparent;
    cursor: pointer;
    height: 2rem;
    border-radius: 3px;
  }

  .margin-horizontal-small {
    margin-left: 2px;
    margin-right: 2px;
  }

  .default, .delete {
    background: transparent;
    color: white;
  }

  .default:hover {
    background: var(--colour-primary-quater);
    color: white;
  }
    
  .selected {
    background: var(--colour-primary-quater);
    color: var(--colour-primary);
  }

  .delete:hover {
    background: #f0343450;
    color: white;
  }

  .left-section {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }

  .main-section {
    position:relative;
    margin: 0 auto;
    transform: translate(-120px, 0);
    color: white;
  }
  
  .right-section {
    margin-right: 5px;
    display: flex;
    align-items: center;
    margin: 1 auto;
  }
}