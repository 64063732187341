.report-type {
  text-align: center;
  font-size: 12px;
}

.report-links {
  display: flex;
  justify-content: space-evenly;
  width: 178px;
  font-size: 12px;

  .report-link {
    color: var(--colour-primary);
  }
  

  .report-link-item{
    margin-top: 6px;
    transform: translate(0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}