.rp-dashboard-bar {
  display: flex;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  overflow-x: auto;
  align-content: flex-start;
  padding: 5px;

  .ant-card-bordered {
    border-radius: 3px;
    border: 1px solid #cccc;
  }

  .active-card {
    border-bottom: 3px solid var(--colour-primary);
  }

  .ant-card {
    margin: 5px;
    min-height: 80px;
    min-width: 150px;
    height: 80px;
    width: 150px;
    max-height: 80px;
    max-width: 150px;

    .ant-card-head { 
      position: fixed;
      min-height: 0;
      max-height: 0px;
      height: 0px;
      padding: 0 0;
      color: transparent;
      border-bottom: transparent;
      border: none;
      // margin-top: -8px;
      margin-left: 125px;

      .ant-card-extra {
        padding: 0 0;
        opacity: 0;
        transition: opacity ease-in-out 200ms;
      }
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0px 5px;
    color: #3b3b3b;
    align-items: center;
    height: 100%;

    .card-info {
      align-items: center;
      font-size: 32px;
      color: var(--colour-primary);
      max-width: 100%;
      min-height: 50%;
      max-height: 50%;
    }

    .card-title {
      margin-top: 5px;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      min-height: 50%;
      max-height: 50%;
    }
  }

  .ant-card:hover {
    .ant-card-extra {
      opacity: 1;
    }

    .active-card {
      border-bottom: 3px solid var(--colour-primary);
    }
  }
}