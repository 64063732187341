.AdaptiveForm {
  position: unset;

  .ant-btn {
    max-width: 100px;
  }

  .AddGroup {
    align-items: flex-end;
  }

  .ant-input {
    max-height: 24px;
  }

  .Heading {
    font-size: 0.95rem !important;
  }

  .Field {
    margin: 0 !important;

    .Label {
      padding: 0 !important;

      .Name {
        font-size: 0.95rem !important;
      }
    }
  }

  div[id^='AddGroup:'] {
    max-width: 70px;
  }

  div[id^='AddJoin:'] {
    .Label {
      margin-left: auto;
      max-width: 60px !important;
    }
  }

  div[id^='AddRule:'] {
    max-width: 60px;
  }

  .AddJoin {
    align-items: flex-end;
  }

  .AddRule {
    align-items: flex-end;
  }

  .SectionChildren {
    display: flex;
    flex-direction: column;
    gap: 0.333rem;
  }

  .TableAliasClass {
    .flex {
      display: flex;
    }

    .flex-center {
      align-items: center;

      & > .ant-select {
        min-width: calc(50% - 1.5rem);
      }

      & > p {
        margin: 0 1rem !important;
      }
    }
  }

  .OrderBySelect {
    .flex {
      display: flex;
      flex-direction: row;
    }

    .flex-center {
      align-items: center;
    }

    .ant-select {
      min-width: 60%;
      max-width: 60%;
    }

    .ant-radio-group {
      display: flex;
      width: 100%;

      .ant-radio-button-wrapper {
        min-width: 30px;
        text-align: center;
      }
    }
  }

  .RemoveItem > button {
    margin-top: 0.25rem;
    padding: 0 !important;
  }

  label {
    margin-bottom: 0 !important;
  }

  div[id^='JoinRule:'] {
    div[id^='JoinRule:'] {
      background: #f5f5f5;
      border-radius: 3px;
      padding: 0.3em;
      box-sizing: border-box;

      .RemoveItem > button {
        padding: 0 !important;
      }

      label {
        margin-bottom: 0 !important;
      }
    }
  }

  div[id^='QueryRule:'] {
    div[id^='QueryRule:'] {
      div[id^='lValue'] {
        width: calc((100% - 125px) / 2 );
      }

      div[id^='Operator:'] {
        max-width: 100px;
        .OperandField {
          max-width: 100px;
          .ant-select {
            min-width: 100px;
            max-width: 100px;
          }
        }
      }

      div[id^='rValue'] {
        width: calc((100% - 125px) / 2 );
        align-items: center;
      
          .ant-radio-group {
            display: flex;
            margin-bottom: 5px;
      
            .ant-radio-button-wrapper {
              min-width: 50%;
              max-width: 50%;
              text-align: center;
            }
        }
      }
    }
  }

  .Field {
    margin-right: unset;

    & > div[id^='QueryRule:'] {
      border-top: solid 1px #c2c2c2;
      padding-top: 1em;
    }

    & > div[id^='QueryGroup:'] {
      margin: 0 0.5em 0.5em 0.5em !important;
      box-sizing: border-box;
      padding: 0.5rem;
      background-color: rgba(244, 218, 124, 0.125);
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 1px;
    }
  }

  .ant-radio-group {
    .ant-radio-button-wrapper {
      text-align: center;
      line-height: 20px;
    }
  }

  .Heading {
    font-size: 1rem;
    color: var(--colour-neutral-light);
    margin-bottom: 0.333rem;
    margin-left: 0.5em !important;
  }

  .ant-btn:focus {
    color: #40a9ff;
    border-color: #d9d9d9;
  }

  .AndOrToggle {
    position: absolute;
    margin-left: 3px;

    .ant-radio-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-radio-button-wrapper {
        min-width: 50px;
        max-width: 50px;
        text-align: center;
        line-height: 22px;
      }
    }
  }

  .FieldSelect {
    .ant-select {
      min-width: 100%;
    }
  }

  div[id^='RemoveItem:'] {
    max-width: 12.5px;
  }

  .RemoveItem {
    color: #f03434;
    margin-top: -3px;
  }

  .flex {
    display: flex;
    flex-direction: row;
  }

  .flex-end {
    align-content: flex-end;
  }

  .ant-select-selection-item-remove {
    margin-top: -1px;
  }

  .ant-select-clear { 
    margin-top: -8px !important;
  }
}
