.Reports {
  background: #e5e5e5;
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 40px 1fr;
  grid-template-areas:
    'sidebar commandbar'
    'sidebar content';

  .content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);
    overflow: hidden;
  }

  .ant-table-wrapper {
    flex: 1 1 auto;
    overflow: auto;
  }

  .card-table-view {
    display: flex;
    flex: 1 1 auto;

    .ant-collapse {
      align-self: flex-end;
      display: flex;
      flex: 1 1 auto;

      .ant-collapse-item {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        .ant-collapse-content-box {
          padding: 0px;
          display: flex;
          max-height: calc(100vh - 610px);
          max-width: calc(100vw - 240px);
        }
      }
    }
  }

  .card-table {
    max-height: calc(100vh - 60px);
    display: flex;
    align-content: flex-start;
    min-height: 525px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 5px;

    .ant-card-hoverable {
      cursor: unset;
    }

    .ant-card-bordered {
      border-radius: 3px;
      border: 1px solid #cccc;
    }

    .ant-card {
      margin: 5px;
      padding: 5px;
      min-width: 250px;
      max-width: 250px;
      max-height: 490px;

      .ant-card-head {
        min-height: 0;
        max-height: 24px;
        height: 24px;
        padding: 0 0;
        border-bottom: 1px solid #3b3b3b;
        border: none;
        color: #3b3b3b;
        min-height: 0px;
        height: 30px;

        .ant-card-head-title {
          padding: 0 0;
          margin-left: 10px;
        }

        .ant-card-extra {
          padding: 0 0;
          opacity: 0;
          transition: opacity;
          margin-right: 10px;
        }
      }

      .ant-card-head:hover {
        .ant-card-extra {
          opacity: 1;
        }
      }

      .ant-card-cover {
        width: 120px;
        margin: 0 auto;
      }

      .ant-card-body {
        margin: 0 auto;
        padding: 0px;
        color: #3b3b3b;

        .ant-card-meta-title {
          color: #3b3b3b;
          text-align: center;
          font-size: 16px;
        }
      }
    }
  }

  .card-list {
    max-height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 5px;

    .ant-card-bordered {
      border-radius: 3px;
      border: 1px solid #cccc;
    }

    .ant-card {
      margin: 5px;
      padding: 10px;
      width: 200px;
      min-height: 200px;
      height: 200px;
      max-height: 300px;

      .ant-card-head {
        position: absolute;
        min-height: 0;
        max-height: 24px;
        height: 24px;
        padding: 0 0;
        color: transparent;
        border-bottom: transparent;
        border: none;
        margin-top: -5px;
        margin-left: 160px;

        .ant-card-extra {
          padding: 0 0;
          opacity: 0;
          transition: opacity ease-in-out 200ms;
        }
      }

      .ant-card-cover {
        display: flex;
        flex-direction: column;
        height: 100px;
        margin: 0 auto;
        align-items: center;
        color: var(--colour-primary);

        .fal,
        .fak {
          margin: 0 auto;
          width: unset;
        }
      }

      .ant-card-body {
        margin: 0 auto;
        padding: 0px;
        color: #3b3b3b;

        .add-card {
          display: flex;
          align-items: center;
          color: #3b3b3b;

          .fal {
            position: relative;
            margin: 0 auto;
          }
        }

        .ant-card-meta-title {
          color: #3b3b3b;
          text-align: center;
          font-size: 16px;
        }

        .ant-card-meta-description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .ant-card-actions {
        border-top: none;
      }
    }

    .ant-card:hover {
      .ant-card-extra {
        opacity: 1;
      }
    }
  }
}

.add-column-modal {
  .ant-modal-header {
    padding: 10px 24px 0 24px;
  }

  .ant-tabs-nav {
    margin: 0;
  }
}

.margin-left-small {
  margin-left: 5px;
}

.margin-right-small {
  margin-right: 5px;
}

.Modal {
  display: flex;
  flex-direction: column;
  width: 640px;
  height: 640px;
  overflow-y: scroll;
}

.anticon {
  vertical-align: 0.125em;
}

.AdaptiveForm .FormElement .Layout.Heading {
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.ant-popover-message-title {
  padding-left: 22px !important;
}

.ant-dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}