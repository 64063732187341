.breadcrumbs {
  & > span:last-child {
  .ant-breadcrumb-link {
     color: var(--colour-white);
     cursor: text;

     i {
       cursor: pointer;
     }
    }
  }
    
  .ant-breadcrumb-link {
    color: var(--colour-neutral-light-light);
    cursor: pointer;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 130px;
    display: inline-block;
    vertical-align: top;
  }

  .ant-breadcrumb-separator {
    color: var(--colour-neutral-dark-light);
  }
}