.table-header {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 40px;
  background: transparent;
  color: white;

  .table-name {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .table-delete {
    transform: translate(0%, 25%);
    margin-left: auto;
    margin-right: 10px;
    color: #f03434;
    cursor: pointer;
  }
}

.table-body {
  display: flex;
  flex-direction: column;

  .add-column-button {
    height: 30px;
    border-radius: 3px;
    border: 1px solid #cccc;
    background-color: var(--colour-primary);
    color: white;
    border: transparent;
    margin: 0 auto;
    margin-bottom: 5px;

    &:focus {
      background-color: var(--colour-primary);
      color: white;
    }
  }

  .add-column-button:hover {
    background: #3b3b3b;
    color: white;
  }

  .table-columns {
    display: flex;
    flex-direction: column;
    margin: 5px;
    margin-left: 10px;
    height: 410px;
    overflow: hidden;
    overflow-y: auto;

    .column {
      display: flex;
      display: row;
      min-height: 32px;

      .column-icon {
        transform: translate(0%, 20%);
        margin-right: 5px;
        color: var(--colour-primary);
        cursor: pointer;
      }

      .column-text {
        transform: translate(0%, 20%);
        max-width: 171px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        color: #3b3b3b;
      }

      .column-delete {
        transform: translate(0%, 20%);
        margin-left: auto;
        margin-right: 5px;
      }
    }
  }
}

.delete-button {
  border: transparent;
  background: transparent;
  color: #f03434;
  cursor: pointer;
}

.checkbox-list {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;

  .ant-checkbox-wrapper {
    margin-left: 8px;
    padding: 2px;
  }
}

