.sidebar {
  grid-area: sidebar;
  height: 100vh;
  width: 240px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(var(--gradient-primary));

  .header {
    align-items: center;
    padding-left: 22px;
    display: flex;
    color: white;
    height: 83px;
  }
  
  .main {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    bottom: 64px;
    top: 88px;
    right: 0;
    left: 0;
  
    .menu_item {
      display: flex;
      flex-direction: column;
      width: 100%;
  
      .menu_item_button {
        cursor: pointer;
        user-select: none;
        color: white;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        flex-wrap: nowrap;
        margin: 0.1rem 1rem;
        border-radius: 3px;
        line-height: 16px;
        
        .menu_item_icon {
          width: 1.5rem;
          flex: 0 0 auto;
          font-size: 1.1rem;
          padding-left:  0.5rem;
        }
        
        .menu_item_label {
          flex: 1 1 auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        
          padding: 0.5rem;
        }
      }
  
      .menu_item_button:hover {
      
        background-color: #fff;
        color: #3b3b3b;
      }
    }
  }
  
  .footer {
    position: absolute;
    flex: 0 0 64px;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

